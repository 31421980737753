import { Injectable } from '@angular/core';
import { BBraunReference, Category, ItemStateCategory, MasterDataProduct } from '@shared/models/project-details.model';
import { EMDNEntity } from '@shared/models/offline.model';
import { INA_STATIC_DATA_INDEXED_DB_NAME, INA_SYNC_DATA_INDEXED_DB_VERSION, ITEM_PRIMARY_KEY } from '@pwa/constants/offline.constants';
import Dexie, { PromiseExtended, Table } from 'dexie';
import { from } from 'rxjs';
import { CategoryReasonsVM } from '@shared/models/static-data.model';

@Injectable({
  providedIn: 'root',
})
export class StaticDataDexieService extends Dexie {
  private db?: IDBDatabase;

  itemStateCategory!: Table<ItemStateCategory, number>;
  category!: Table<Category, number>;
  // reason!: Table<Reason, number>;
  // reasonCategory!: Table<ReasonCategory, number>;
  emdn_map!: Table<EMDNEntity, number>;
  defaultCategoryReasonsVM!: Table<CategoryReasonsVM, number>;
  masterDataProduct!: Table<MasterDataProduct, number>;
  bBraunReference!: Table<BBraunReference, number>;

  constructor() {
    super(INA_STATIC_DATA_INDEXED_DB_NAME);
    const db = this;
    // Define tables and indexes
    db.version(INA_SYNC_DATA_INDEXED_DB_VERSION).stores({
      itemStateCategory: `&${ITEM_PRIMARY_KEY}, &name`,
      category: `&${ITEM_PRIMARY_KEY}, &name`,
      emdn_map: `++${ITEM_PRIMARY_KEY}, &emdnCode`,
      defaultCategoryReasonsVM: `++${ITEM_PRIMARY_KEY}, &categoryName`,
      masterDataProduct: `++${ITEM_PRIMARY_KEY}, &articleCode`,
      bBraunReference: `++${ITEM_PRIMARY_KEY},&articleCode`,
    });
  }

  async initDb(): Promise<PromiseExtended<Dexie>> {
    // Dexie handles the creation and upgrading of stores automatically.
    // Additional initialization logic can go here if needed.
    // Since Dexie uses Promises, you can just return the Dexie database open promise.
    await this.open();
    return this;
  }

  async openDatabase(db: { open: () => any }) {
    await db.open();
  }

  public bulkPost<T>(entity: Array<T>, table: Table<T, number | string>) {
    return from(table.bulkPut(entity));
  }

  public bulkPostStaticData<T>(entity: Array<T>, table: Table<T, number | string>) {
    table.clear();
    return from(table.bulkPut(entity));
  }
}
