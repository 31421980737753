import { Injectable } from '@angular/core';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Category, ItemStateCategory } from '@shared/models/project-details.model';
import { Table } from 'dexie';
import { from, of } from 'rxjs';
import { StaticDataDexieService } from '@pwa/indexed-db/dexie-wrapper/static-data-dexie.service';
import { CategoryReasonsVM } from '@shared/models/static-data.model';

@Injectable({
  providedIn: 'root',
})
export class StaticDataDifferentDomainsIdbService {
  private readonly itemStateCategoryStore: Table<ItemStateCategory, number>;
  private ITEM_STATE_CATEGORY_STORE_NAME = 'itemStateCategory';

  private readonly categoryStore: Table<Category, number>;
  private CATEGORY_STORE_NAME = 'category';

  private readonly defaultCategoryReasonsVMStore: Table<CategoryReasonsVM, number>;
  private DEFAULT_CATEGORY_REASON_STORE_NAME = 'defaultCategoryReasonsVM';

  constructor(
    private staticDataIdbService: StaticDataDexieService,
    readonly responseHandlerService: ResponseHandlerService
  ) {
    this.itemStateCategoryStore = this.staticDataIdbService.table(this.ITEM_STATE_CATEGORY_STORE_NAME);
    this.categoryStore = this.staticDataIdbService.table(this.CATEGORY_STORE_NAME);
    this.defaultCategoryReasonsVMStore = this.staticDataIdbService.table(this.DEFAULT_CATEGORY_REASON_STORE_NAME);
  }

  postItemStateCategory(itemStateCategories: ItemStateCategory[]) {
    if (itemStateCategories) {
      return this.staticDataIdbService.bulkPostStaticData(itemStateCategories, this.itemStateCategoryStore);
    }
    return of([]); // Return an empty array wrapped in an observable
  }

  postCategories(categories: Category[]) {
    if (categories) {
      return this.staticDataIdbService.bulkPostStaticData(categories, this.categoryStore);
    }
    return of([]); // Return an empty array wrapped in an observable
  }

  getAllReasons() {
    return from(this.defaultCategoryReasonsVMStore.toArray());
  }

  getReasonsByQualityCriteriaGroup(qualityCriteriaGroup: string) {
    return from(this.defaultCategoryReasonsVMStore.where('categoryName').equals(qualityCriteriaGroup).first());
  }

  postDefaultProjectCategoryReasons(categoryReasonsDTO: CategoryReasonsVM[]) {
    return this.staticDataIdbService.bulkPostStaticData(categoryReasonsDTO, this.defaultCategoryReasonsVMStore);
  }
}
