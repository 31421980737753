import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Paging } from '../../models/paging/paging.class';

@Injectable()
export class HttpService {
  /**
   * Constructor for dependency injection. The injected HttpClient will be passed into the internal
   * {InternalRequestBuilder} instances.
   *
   * Usage example: builder.request('rest', 'users', String(123)).param('create', String(true)).get()
   *
   * @param {HttpClient} http Angular HttpClient service
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * Helper method to join an array of string parameters into a single string suitable for use as a path segment (e.g. '/ids/1,2,3').
   *
   * @param values parameter values to join
   */
  static array(values: Array<string>) {
    return values.join(',');
  }

  /**
   * Starts a new builder instance with the given path segments.
   *
   * @param {string} segments REST path segments
   * @returns {InternalRequestBuilder} builder instance
   */
  request(route: string): InternalRequestBuilder {
    return new InternalRequestBuilder(this.http, route);
  }
}

class InternalRequestBuilder {
  private headerBuilder = new HttpHeaders();
  private paramsBuilder?: HttpParams;
  private bodyBuilder: any;
  private fullResponse = false;
  private textResponse = false;

  constructor(
    private readonly http: HttpClient,
    private readonly route: string
  ) {}

  /**
   * Executes the constructed request as a HEAD request.
   * @returns {Observable<any>} observable for the response
   */
  head(): Observable<any> {
    return this.http.head(this.urlBuilder(), this.createOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a GET request.
   * @returns {Observable<any>} observable for the response
   */
  get(): Observable<any> {
    return this.http.get(this.urlBuilder(), this.createOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a GET request.
   * @returns {Observable<any>} observable for the response
   */
  getBlob(): Observable<any> {
    return this.http.get(this.urlBuilder(), this.createFileOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a POST request.
   * @returns {Observable<any>} observable for the response
   */
  postBlob(): Observable<any> {
    return this.http.post(this.urlBuilder(), this.bodyBuilder, this.createFileOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a POST request.
   * @returns {Observable<any>} observable for the response
   */
  post(): Observable<any> {
    return this.http.post(this.urlBuilder(), this.bodyBuilder, this.createOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a POST request.
   * @returns {Observable<any>} observable for the response
   */
  fileExport(): Observable<any> {
    return this.http.post(this.urlBuilder(), this.bodyBuilder, this.createFileOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a PATCH request.
   * @returns {Observable<any>} observable for the response
   */
  patch(): Observable<any> {
    return this.http.patch(this.urlBuilder(), this.bodyBuilder, this.createOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a PUT request.
   * @returns {Observable<any>} observable for the response
   */
  put(): Observable<any> {
    return this.http.put(this.urlBuilder(), this.bodyBuilder, this.createOptions()).pipe(map((data) => data as any));
  }

  /**
   * Executes the constructed request as a DELETE request.
   * @returns {Observable<any>} observable for the response
   */
  delete(): Observable<any> {
    return this.http.delete(this.urlBuilder(), this.createOptions()).pipe(map((data) => data as any));
  }

  /**
   * Sets the HTTP headers. Calling this method replaces the default headers.
   *
   * @param {HttpHeaders} headers new HttpHeaders
   * @returns {InternalRequestBuilder} builder
   */
  headers(headers: HttpHeaders): InternalRequestBuilder {
    this.headerBuilder = headers;
    return this;
  }

  /**
   * Sets the paging query parameters. If used this method must be called before any other methods that manipulate
   * query parameters.
   *
   * @param {Paging} paging paging data
   * @returns {InternalRequestBuilder} builder
   */
  paging(paging: Paging): InternalRequestBuilder {
    return this.params(paging.apply());
  }

  /**
   * Sets the query parameters. If used this method must be called before any other methods that manipulate
   * query parameters.
   *
   * @param {HttpParams} params query parameters
   * @returns {InternalRequestBuilder} builder
   */
  params(params: HttpParams): InternalRequestBuilder {
    if (!this.paramsBuilder) {
      this.paramsBuilder = params;
      return this;
    } else {
      throw new Error('HttpParams already set (use param(string, string) to append)');
    }
  }

  /**
   * Appends a query parameter. Does not set the parameter if the value is considered empty.
   *
   * @param {string} name parameter name
   * @param {string} value parameter value
   * @returns {InternalRequestBuilder} builder
   */
  param(name: string, value: string): InternalRequestBuilder {
    if (!value) {
      return this;
    }
    if (!this.paramsBuilder) {
      return this.params(new HttpParams().set(name, value));
    } else {
      this.paramsBuilder = this.paramsBuilder.append(name, value);
      return this;
    }
  }

  /**
   * Sets the body that should be passed as a payload.
   *
   * @param body payload
   * @returns {InternalRequestBuilder} builder
   */
  body(body: any): InternalRequestBuilder {
    if (!this.bodyBuilder) {
      this.bodyBuilder = this.cleanEmptyProperties(body);
      return this;
    } else {
      throw new Error('Body already set');
    }
  }

  /**
   * Set empty string property values to null.
   * @param body payload
   */
  cleanEmptyProperties(body: any): any {
    const newBody = { ...body }; // Create a shallow copy of the original object

    Object.keys(newBody).forEach((key) => {
      if (newBody[key] === '') {
        newBody[key] = null;
      }
    });

    return newBody;
  }

  /**
   * Flag request the full response information instead of just the body. Required for example when the caller needs to access the
   * HTTP status code.
   *
   * @returns {InternalRequestBuilder} builder
   */
  withFullResponse(): InternalRequestBuilder {
    this.fullResponse = true;
    return this;
  }

  /**
   * Manipulates the responseType property in the http call. Required when the call needs to have a text response.
   *
   * @returns {InternalRequestBuilder} builder
   */
  withTextOptions(): InternalRequestBuilder {
    this.textResponse = true;
    return this;
  }

  private urlBuilder(): string {
    return environment.backendUrl + '/' + this.route;
  }

  private createOptions(): RequestOptions {
    return {
      headers: this.headerBuilder,
      params: this.paramsBuilder,
      observe: this.fullResponse ? 'response' : 'body',
      responseType: this.textResponse ? 'text' : 'json',
    };
  }

  private createFileOptions(): RequestOptions {
    return {
      headers: this.headerBuilder,
      params: this.paramsBuilder,
      observe: this.fullResponse ? 'response' : 'body',
      responseType: 'blob',
    };
  }
}

/**
 * Explicit interface for the request options. Required to work around a TypeScript compiler typing 'bug' (maybe not a really a bug).
 */
interface RequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}
