<cx-header>
  <cx-header-title>Aesculap® Inventory Analysis Theme Page</cx-header-title>
  <cx-header-quickbar>
    <button mat-icon-button class="cx-header-white-icon" [routerLink]="'/' + projectOverviewsRoute">
      <mat-icon class="white-medium-icon">home</mat-icon>
    </button>
  </cx-header-quickbar>
</cx-header>

<div class="wrapper">
  <h2 class="text-purple">Aesculap Inventory Analisys Number Input</h2>
  <h3>Default</h3>
  <div class="my-m">
    <aia-number-fc></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Read only</h3>
  <div class="my-m">
    <aia-number-fc [readonly]="true"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Disabled</h3>
  <div class="my-m">
    <aia-number-fc [disabled]="true"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Starting from 50</h3>
  <div class="my-m">
    <aia-number-fc [fc]="fiftyValueFC"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Minimum value set to 10</h3>
  <div class="my-m">
    <aia-number-fc [min]="10"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Maximum value set to 100</h3>
  <div class="my-m">
    <aia-number-fc [max]="100"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Tab cycles through the increase and decrease buttons</h3>
  <div class="my-m">
    <aia-number-fc [tabIndex]="0"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Tab does not cycle through the increase and decrease buttons</h3>
  <div class="my-m">
    <aia-number-fc></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Custom placeholder</h3>
  <div class="my-m">
    <aia-number-fc [placeholder]="'XX'"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h3>Hidden buttons</h3>
  <div class="my-m">
    <aia-number-fc [showButtons]="false"></aia-number-fc>
  </div>
  <mat-divider></mat-divider>

  <h2 class="text-purple">Aesculap Inventory Analisys Logo/Icon</h2>
  <div class="row">
    <div class="col-6">
      <div class="pr-sm">
        <h3>Favicon</h3>
        <img src="favicon.ico" alt="fav icon" />
        <mat-divider></mat-divider>

        <h3>96x96</h3>
        <img src="assets/icons/icon-96x96.png" alt="logo 96x96" />
        <mat-divider></mat-divider>

        <h3>192x192</h3>
        <img src="assets/icons/icon-192x192.png" alt="logo 192x192" />
      </div>
    </div>
    <div class="col-6">
      <h3>512x512</h3>
      <img src="assets/icons/icon-512x512.png" alt="logo 512x512" />
    </div>
  </div>

  <mat-divider></mat-divider>
</div>
