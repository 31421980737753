import { Injectable } from '@angular/core';
import { HttpService } from '@shared/services/http/http.service';
import { Observable } from 'rxjs';
import { CategoryReasonsVM, ProjectReasonsDTO } from '@shared/models/static-data.model';
import { Reason } from '@shared/models/project-details.model';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReasonService {
  private REASON_ENDPOINT = 'project-category-reasons';

  constructor(private readonly httpService: HttpService) {}

  public getAllReasons(): Observable<CategoryReasonsVM[]> {
    return this.httpService
      .request(`${this.REASON_ENDPOINT}/default`)
      .get()
      .pipe(map((projectReasonDTO) => this.transformToCategoryReasonVM(projectReasonDTO)));
  }

  public getReasonsByProjectIdAndTransformToReasonVM(projectId?: number | string): Observable<CategoryReasonsVM[]> {
    const params = new HttpParams().set('projectId', projectId?.toString() ?? 0);
    return this.httpService
      .request(`${this.REASON_ENDPOINT}`)
      .params(params)
      .get()
      .pipe(map((projectReasonDTO) => this.transformToCategoryReasonVM(projectReasonDTO)));
  }

  public getReasonsByProjectId(projectId?: number | string): Observable<ProjectReasonsDTO> {
    const params = new HttpParams().set('projectId', projectId?.toString() ?? 0);
    return this.httpService.request(`${this.REASON_ENDPOINT}`).params(params).get();
  }

  public putReasons(body: ProjectReasonsDTO): Observable<ProjectReasonsDTO> {
    return this.httpService.request(`${this.REASON_ENDPOINT}`).body(body).put() as Observable<ProjectReasonsDTO>;
  }

  transformToCategoryReasonVM(projectReasonsDTO: ProjectReasonsDTO): CategoryReasonsVM[] {
    const result: CategoryReasonsVM[] = [];
    const itemStateNameMap = projectReasonsDTO.itemStateCategoryIdMap;
    const itemStateCategoryIdMap = this.convertItemStateCategoryIdToMap(itemStateNameMap);
    const itemStateCategoryIds = itemStateCategoryIdMap.map((item) => item.key);
    const categoriesData = projectReasonsDTO.categories;

    for (const categoryData of categoriesData) {
      const reasonsData = categoryData.reasons;
      let repairReasonsData;
      let replacementReasonsData;
      let superficialIssuesReasonsData;

      for (const itemStateCategoryId of itemStateCategoryIds) {
        const currentReasonData = this.collectReasonsByItemStateCategoryId(reasonsData, itemStateCategoryId, categoryData.categoryName);
        if (itemStateCategoryId === 51) {
          repairReasonsData = currentReasonData;
        } else if (itemStateCategoryId === 101) {
          replacementReasonsData = currentReasonData;
        } else if (itemStateCategoryId === 151) {
          superficialIssuesReasonsData = currentReasonData;
        }
      }

      const categoryReasonVM: CategoryReasonsVM = {
        categoryId: categoryData.categoryId,
        categoryName: categoryData.categoryName,
        repairReasons: repairReasonsData,
        replacementReasons: replacementReasonsData,
        superficialIssueReasons: superficialIssuesReasonsData,
      };

      result.push(categoryReasonVM);
    }

    return result;
  }

  convertItemStateCategoryIdToMap(itemStateCategoryIdMap?: any) {
    if (!itemStateCategoryIdMap) {
      itemStateCategoryIdMap = {
        '1': 'items_in_good_quality',
        '101': 'items_in_need_of_replacement',
        '51': 'items_in_need_of_repair',
        '151': 'items_with_superficial_issues',
      };
    }
    const resultAsMapObject = new Map();

    Object.entries(itemStateCategoryIdMap).forEach(([key, value]) => {
      if (Number(key) !== 1) {
        resultAsMapObject.set(Number(key), value);
      }
    });

    const entriesArray: any[] = [];
    resultAsMapObject?.forEach((value, key) => {
      entriesArray.push({ key, value });
    });
    return entriesArray;
  }

  collectReasonsByItemStateCategoryId(reasons: Reason[], itemStateCategoryId: number, categoryName: string): Reason[] {
    const result: Reason[] = [];
    reasons.forEach((reason) => {
      if (itemStateCategoryId === reason.itemStateCategory) {
        reason.categoryName = categoryName;
        result.push(reason);
      }
    });
    return result;
  }
}
