import { User } from '@shared/interfaces/user.interface';
import { createReducer, on } from '@ngrx/store';
import * as AppAction from './../actions/application.actions';
import { DEFAULT_LANGUAGE } from '@shared/constants/translations/translations.constants';

export const ApplicationFeatureKey = 'global';

export interface AppState {
  user: User;
  loading: boolean;
  isOnline: boolean;
  appLanguage: string;
}

export const initialState: AppState = {
  user: {},
  loading: false,
  isOnline: false,
  appLanguage: DEFAULT_LANGUAGE,
};

export const reducer = createReducer<AppState>(
  initialState,
  on(AppAction.startLoadingAction, (state) => ({ ...state, loading: true })),
  on(AppAction.stopLoadingAction, (state) => ({ ...state, loading: false })),
  on(AppAction.loadUserDataAction, (state: AppState) => ({
    ...state,
    loading: true,
  })),
  on(AppAction.loadUserDataSuccessAction, (state: AppState, { payload }: { payload: { user: User } }) => ({
    ...state,
    loading: false,
    user: payload.user,
  })),
  on(AppAction.setIsOnlineAction, (state: AppState, { payload }: { payload: { isOnline: boolean } }) => ({
    ...state,
    isOnline: payload.isOnline,
  })),
  on(AppAction.setApplicationLanguage, (state: AppState, payload: { appLanguage: string }) => {
    return {
      ...state, // Spread the existing state
      appLanguage: payload.appLanguage, // Overwrite the appLanguage property
    };
  })
);
export const getUser = (state: AppState) => state.user;
export const getAppLoading = (state: AppState) => state.loading;
export const getIsOnline = (state: AppState) => state.isOnline;
export const getAppLanguage = (state: AppState) => state.appLanguage;
