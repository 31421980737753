import { Injectable, OnDestroy } from '@angular/core';
import { Set, SetTitle } from '@shared/models/project-details.model';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Table } from 'dexie';
import { catchError, from, map, Observable, Subscription, switchMap, tap, throwError } from 'rxjs';
import { ItemIdbService } from '@pwa/indexed-db/services/dynamic-data/item/item-idb.service';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { SYNC_CREATE_DATA_VERSION_VALUE } from '@pwa/constants/offline.constants';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { translate, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class SetIdbService implements OnDestroy {
  private readonly setStore: Table<Set, number>;
  private SET_STORE_NAME = 'set';

  translocoSub: Subscription;

  constructor(
    private projectDataIndexedDbService: ProjectDataDexieService,
    private syncDataIndexedDbService: SyncDataDexieService,
    readonly responseHandlerService: ResponseHandlerService,
    private itemIdbService: ItemIdbService,
    private translocoService: TranslocoService
  ) {
    this.setStore = this.projectDataIndexedDbService.table(this.SET_STORE_NAME);

    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  postSets(sets: Set[] | undefined) {
    this.logDebug('Post offline Sets succeeds');
    if (sets) {
      return this.projectDataIndexedDbService.bulkPost(sets, this.setStore);
    }
    return;
  }

  post(set: Set, projectId?: number, parent?: SetTitle) {
    return this.projectDataIndexedDbService.getLatestRecordId(this.setStore).pipe(
      tap((latestRecordId) => {
        this.logDebug(latestRecordId);
      }),
      switchMap((latestRecordId) => {
        if (latestRecordId === undefined || latestRecordId === null) {
          return throwError(() => 'LatestRecordId is invalid');
        }
        // field "id" is not available
        const newId = latestRecordId + 1;
        const offlineSet = {
          ...set,
          id: newId, // type string and is unique
          titleId: set.titleId,
          serialNumber: set.serialNumber,
          version: SYNC_CREATE_DATA_VERSION_VALUE,
          deleted: false,
          // offline fields
          projectId: Number(projectId),
          image: set.image,
          hasCloudParent: parent?.version === SYNC_CREATE_DATA_VERSION_VALUE ? false : true,
        } as Set;

        return this.projectDataIndexedDbService.setExists(set.serialNumber, set.titleId).pipe(
          switchMap((setExists) => {
            if (setExists) {
              return throwError(() => `Set with Serial Number ${set?.serialNumber} already exists in the current Set Title`);
            } else {
              return this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore).pipe(
                switchMap((id: number | string) => this.getById(id)),
                tap((result) => {
                  this.logDebug(result);
                  if (result) {
                    this.syncDataIndexedDbService.postSet(result).subscribe({
                      next: (response) => {
                        this.logDebug('Post to ina_sync_post_data succeeds');
                        this.logDebug(`${response}`);
                      },
                      error: (error) => {
                        this.logDebug('Post to ina_sync_post_data fails');
                        this.logDebug(error);
                      },
                    });
                  }
                }),
                catchError((error) => {
                  this.responseHandlerService.handleError(translate('snacks.offline-creation-of-set-failed'));
                  console.error(error);
                  return throwError(error); // Rethrow the error for further handling
                })
              );
            }
          })
        );
      })
    );
  }

  getById(id: number | string): Observable<Set | undefined> {
    return from(this.setStore.where('id').equals(id).first()).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-get-set-by-id-failed'));
        console.error(error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  put(set: Set, projectId?: number) {
    // field "id, "deleted" are available
    const offlineSet = {
      ...set,
      titleId: set.titleId,
      projectId: Number(projectId),
      weight: Number(set.weight),
      image: set.image,
    } as Set;
    return this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore).pipe(
      switchMap((id: number | string) => this.getById(id)),
      tap((result) => {
        this.logDebug(result);
        if (result) {
          this.syncDataIndexedDbService.postSet(result).subscribe({
            next: (response) => {
              this.logDebug('Post to ina_sync_put_data succeeds');
              this.logDebug(`${response}`);
            },
            error: (error) => {
              this.logDebug('Post to ina_sync_put_data fails');
              this.logDebug(error);
            },
          });
        }
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-update-of-set-failed'));
        console.error(translate('snacks.offline-update-of-set-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  /**
   * Upload a photo as a field directly in IndexedDB
   * Occur during downloading all Project photos process for offline mode
   * @param offlineSet
   */
  uploadPhoto(offlineSet: Set): Observable<string | number> {
    return this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-upload-photo-failed'));
        console.error('[uploadPhoto] ', error);
        return throwError(() => error);
      })
    );
  }

  getAllSetsBySetTitleId(titleId: number | string): Observable<Set[]> {
    return from(
      this.setStore
        .where('titleId')
        .equals(titleId)
        .and((x: any) => !x.deleted)
        .toArray()
    ).pipe(
      map((sets) => sets.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-getting-of-all-sets-failed'));
        console.error(translate('snacks.offline-getting-of-all-sets-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  delete(setId?: number | string, projectId?: number) {
    if (setId) {
      return this.getById(setId).pipe(
        switchMap((setTitle) => {
          const deleteSet = {
            ...setTitle,
            deleted: true,
          } as Set;
          return deleteSet.version === SYNC_CREATE_DATA_VERSION_VALUE ? this.hardDelete(deleteSet.id) : this.put(deleteSet, projectId);
        })
      );
    } else {
      return throwError(() => translate('snacks.offline-deletion-of-set-failed'));
    }
  }

  hardDelete(setId?: number | string): Observable<any> {
    if (setId) {
      return this.projectDataIndexedDbService.hardDelete(setId, this.setStore).pipe(
        tap((result) => {
          this.syncDataIndexedDbService.hardDeleteSet(setId).subscribe({
            next: (response) => {
              this.logDebug('Delete to ina_sync_post_data succeeds');
              this.logDebug(`${response}`);
            },
            error: (error) => {
              this.logDebug('Delete to ina_sync_post_data fails');
              this.logDebug(error);
            },
          });
        }),
        catchError((error) => {
          this.responseHandlerService.handleError(translate('snacks.offline-deletion-of-set-title-failed'));
          console.error(translate('snacks.offline-deletion-of-set-title-failed'), error);
          return throwError(error); // Rethrow the error for further handling
        })
      );
    } else {
      // Immediately return an error if the speciality or its idbKey is not valid
      return throwError(() => new Error('Speciality is null or idbKey is undefined.'));
    }
  }

  /**
   * Synchronization services
   */
  putAndUpdateVersion(set: Set) {
    const offlineSet = { ...set };

    return this.syncDataIndexedDbService.postSet(offlineSet).pipe(
      tap((response) => {
        this.logDebug('Set putAndUpdateVersion to syncDataIndexedDbService ran');
        this.logDebug(`${response}`);
      }),
      switchMap(() =>
        this.projectDataIndexedDbService.insertOrReplace(offlineSet, this.setStore).pipe(
          tap((result) => {
            this.logDebug('Set putAndUpdateVersion to appDataIndexedDbService ran');
            this.logDebug(result);
          })
        )
      ),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-update-of-set-failed'));
        console.error(translate('snacks.offline-update-of-set-failed'), error);
        return throwError(() => error);
      })
    );
  }

  patch(set: Set): Observable<number | string> {
    return this.getById(set.id!).pipe(
      switchMap((idbSet) => {
        const patchedSet = { ...idbSet, ...set };
        return this.syncDataIndexedDbService.postSet(patchedSet);
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-update-of-set-failed'));
        console.error(translate('snacks.offline-update-of-set-failed'), error);
        return throwError(() => error);
      })
    );
  }

  private logDebug(message: any) {
    // console.debug('[set-idb.service.ts]: ' + message + '.');
  }
}
