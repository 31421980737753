import { Injectable } from '@angular/core';
import { Table } from 'dexie';
import { EMDNEntity } from '@shared/models/offline.model';
import { from, take } from 'rxjs';
import { StaticDataDexieService } from '@pwa/indexed-db/dexie-wrapper/static-data-dexie.service';

@Injectable({
  providedIn: 'root',
})
export class EmdnIdbService {
  public readonly emdnStore: Table<EMDNEntity, number>;
  private EMDN_STORE_NAME = 'emdn_map';

  constructor(private staticDataIndexedDbService: StaticDataDexieService) {
    this.emdnStore = this.staticDataIndexedDbService.table(this.EMDN_STORE_NAME);
  }

  isIndexedDBTableEmpty() {
    return from(this.emdnStore.count().then((count) => count === 0));
  }

  postEmdnOffline(masterData: EMDNEntity[]) {
    return this.staticDataIndexedDbService.bulkPost(masterData, this.emdnStore).pipe(take(1));
  }
}
