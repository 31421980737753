import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { HttpService } from '@shared/services/http/http.service';
import { Project } from '@shared/models/project-overviews.model';
import { ProjectIdbService } from '@app/modules/pwa/indexed-db/services/dynamic-data/project/project-idb.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { SpecialityIdbService } from '@app/modules/pwa/indexed-db/services/dynamic-data/speciality/speciality-idb.service';
import { Item, ItemState, Set, SetTitle, Speciality } from '@shared/models/project-details.model';
import { SetTitleIdbService } from '@app/modules/pwa/indexed-db/services/dynamic-data/set-title/set-title-idb.service';
import { catchError } from 'rxjs/operators';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { OfflineStaticDataDTO } from '@shared/models/offline.model';
import { SetIdbService } from '@pwa/indexed-db/services/dynamic-data/set/set-idb.service';
import { ItemIdbService } from '@pwa/indexed-db/services/dynamic-data/item/item-idb.service';
import { StaticDataDifferentDomainsIdbService } from '@pwa/indexed-db/services/static-data/static-data-different-domains-idb.service';
import { MasterDataProductIdbService } from '@pwa/indexed-db/services/static-data/master-data-product-idb.service';
import * as ApplicationActions from '@app/store/actions/application.actions';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/store/reducers';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { DownloadDataService } from '@pwa/services/download/download-data.service';
import { EmdnIdbService } from '@pwa/indexed-db/services/static-data/emdn-idb.service';
import { SetService } from '@app/modules/project-details/providers/services/set.service';
import { translate, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class OfflineDataService implements OnDestroy {
  translocoSub: Subscription;

  constructor(
    readonly httpService: HttpService,
    readonly syncDataIndexedDbService: SyncDataDexieService,
    readonly appDataIndexedDbService: ProjectDataDexieService,
    readonly staticDataIdbService: StaticDataDifferentDomainsIdbService,
    readonly projectIdbService: ProjectIdbService,
    readonly specialityIdbService: SpecialityIdbService,
    readonly setTitleIdbService: SetTitleIdbService,
    readonly setIdbService: SetIdbService,
    readonly setService: SetService,
    readonly itemIdbService: ItemIdbService,
    readonly masterDataProductIdbService: MasterDataProductIdbService,
    readonly downloadDataService: DownloadDataService,
    readonly emdnService: EmdnIdbService,
    readonly responseHandlerService: ResponseHandlerService,
    private readonly store: Store<fromRoot.State>,
    private translocoService: TranslocoService
  ) {
    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  simulateGoingOffline() {
    this.store.dispatch(ApplicationActions.goOfflineAction());
  }

  /**
   * Project data
   */

  downloadAndPersistProjectData(projectId: number): Observable<any> {
    return this.downloadDataService.getProjectData(projectId).pipe(
      switchMap((project: Project) => {
        return this.postNestedProjectData(project);
      })
    );
  }

  private postNestedProjectData(nestedProject: Project) {
    // @ts-ignore
    nestedProject = nestedProject[0];
    const project = this.extractProject(nestedProject);
    const specialities = this.extractSpecialities(nestedProject);

    const allSetTitlesOfEachSpeciality: SetTitle[] = [];
    const allSetsOfEachSetTitle: Set[] = [];
    const allItemsOfEachSet: Item[] = [];

    if (!nestedProject.specialities) {
      return this.projectIdbService.postProject(project);
    }

    for (const speciality of nestedProject.specialities) {
      let currentSetTitles = this.extractSetTitles(speciality);
      if (currentSetTitles) {
        allSetTitlesOfEachSpeciality.push(...currentSetTitles);
        if (speciality.titles) {
          for (const setTitle of speciality.titles) {
            let currentSets = this.extractSets(setTitle);
            if (currentSets) {
              allSetsOfEachSetTitle.push(...currentSets);
              if (setTitle.sets) {
                for (const set of setTitle.sets) {
                  let currentItems = this.extractItems(set, nestedProject.id);
                  if (currentItems) {
                    allItemsOfEachSet.push(...currentItems);
                  }
                }
              }
            }
          }
        }
      }
    }

    return forkJoin([
      this.projectIdbService.postProject(project),
      this.specialityIdbService.postSpecialities(specialities),
      this.setTitleIdbService.postSetTitles(allSetTitlesOfEachSpeciality),
      this.setIdbService.postSets(allSetsOfEachSetTitle),
      this.itemIdbService.postItems(allItemsOfEachSet),
    ]);
  }

  /**
   * Static data
   */

  areMasterDataDownloaded() {
    return this.masterDataProductIdbService.isIndexedDBTableEmpty();
  }
  downloadAndPersistGeneralStaticData(): Observable<any> {
    return this.downloadDataService.getGeneralStaticData().pipe(
      switchMap((offlineStaticDataDTO: OfflineStaticDataDTO) => {
        return this.postGeneralStaticData(offlineStaticDataDTO);
      }),
      catchError((error) => {
        console.error('Error downloading and persisting static data', error);
        return of(null); // handle the error by returning an observable that completes
      })
    );
  }

  private postGeneralStaticData(offlineStaticDataDTO: OfflineStaticDataDTO) {
    const itemStateCategories = offlineStaticDataDTO.itemStateCategory;
    const categories = offlineStaticDataDTO.categories;

    const itemStateCategories$ = this.staticDataIdbService.postItemStateCategory(itemStateCategories);
    const categories$ = this.staticDataIdbService.postCategories(categories);
    const defaultCategoryReasonsVM$ = this.downloadDataService.getDefaultProjectCategoryReasonsVM().pipe(
      switchMap((projectCategoryReasonsVM) => this.staticDataIdbService.postDefaultProjectCategoryReasons(projectCategoryReasonsVM)),
      catchError((error) => {
        console.error('loadUpdateData error:', error);
        return throwError(error);
      })
    );

    return forkJoin([itemStateCategories$, categories$, defaultCategoryReasonsVM$]);
  }

  downloadAndPersistStaticDataEMDNEntries() {
    return this.downloadDataService.getStaticDataEmdnEntries().subscribe((masterData) => {
      this.emdnService.postEmdnOffline(masterData).subscribe({
        next: () => {
          this.responseHandlerService.handleSuccess(translate('snacks.static-emdn-reference-data-has-been-downloaded-for-offline-use'));
        },
        error: (error) => {
          this.responseHandlerService.handleError(translate('snacks.downloading-offline-master-data-failed'));
          console.error(translate('snacks.downloading-offline-master-data-failed'), error);
        },
      });
    });
  }

  isSyncDbEmpty(projectId: number | string) {
    return this.syncDataIndexedDbService.isSyncDbEmpty(projectId);
  }

  areProjectDataAlreadyDownloaded(projectId: number) {
    return this.projectIdbService.getByProjectId(projectId).pipe(
      switchMap((project) => {
        if (project) {
          // Project exists, return an error observable
          return of(true);
        } else {
          // Project does not exist, return a success observable
          return of(false);
        }
      }),
      catchError((error) => {
        // Handle any errors that occurred during the getByProjectId call
        this.responseHandlerService.handleError(translate('snacks.offline-get-project-by-id-failed'));
        console.error(translate('snacks.offline-get-project-by-id-failed'), error);
        return throwError(() => translate('snacks.offline-get-project-by-id-failed'));
      })
    );
  }

  // [IVAN] Note: from this point on nothing is translated, as those are probably debug logs

  // helper methods
  private extractProject(nestedProject: Project): Project {
    const { specialities, ...project } = nestedProject;
    return project;
  }

  private extractSpecialities(nestedProject: Project): Array<Speciality> | undefined {
    const { specialities, ...project } = nestedProject;
    if (!specialities) {
      return undefined;
    }
    return specialities.map((speciality) => this.extractSpeciality(speciality));
  }

  private extractSpeciality(nestedSpeciality: Speciality): Speciality {
    const { titles, ...speciality } = nestedSpeciality;
    speciality.hasCloudParent = true;
    return speciality;
  }

  private extractSetTitles(nestedSpeciality: Speciality): Array<SetTitle> | undefined {
    const { titles, ...speciality } = nestedSpeciality;
    if (!titles) {
      return undefined;
    }
    return titles.map((setTitle) => this.extractSetTitle(setTitle));
  }

  private extractSetTitle(nestedSetTitle: SetTitle): SetTitle {
    const { sets, ...setTitle } = nestedSetTitle;
    setTitle.hasCloudParent = true;
    return setTitle;
  }

  private extractSets(nestedSetTitle: SetTitle): Array<Set> | undefined {
    const { sets, ...setTitle } = nestedSetTitle;
    if (!sets) {
      return undefined;
    }
    return sets.map((set) => this.extractSet(set));
  }

  private extractSet(nestedSet: Set): Set {
    const { items, ...set } = nestedSet;
    set.hasCloudParent = true;
    return set;
  }

  private extractItem(nestedItem: Item, projectId: number): Item {
    nestedItem.projectId = projectId;
    nestedItem.hasCloudParent = true;
    return nestedItem;
  }

  private extractItems(nestedSet: Set, projectId: number): Array<Item> | undefined {
    const { items, ...set } = nestedSet;
    if (!items) {
      return undefined;
    }
    return items.map((item) => this.extractItem(item, projectId));
  }

  private extractItemStates(nestedItem: Item): Array<ItemState> | undefined {
    const { itemStates, ...item } = nestedItem;
    if (!itemStates) {
      return undefined;
    }
    return itemStates;
  }

  // helper methods
  private logDebug(message: any) {
    console.log('[offline-data.service.ts]: ' + message + '.');
  }
}
