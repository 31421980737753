import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/services/auth/auth.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ROUTES } from '@shared/constants/routes/routes.constants';
import { Observable, of } from 'rxjs';
import { ApplicationFacade } from '@app/store/facade/application.facade';

/**
 * Service that guards routes based on user roles.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private authService: AuthService,
    private router: Router,
    private applicationFacade: ApplicationFacade
  ) {}

  /**
   * Determines if the route can be activated based on the User's roles. Currently, the guard is built so that if the
   * app is offline, the guard allows all paths (since there is no way to check for the roles).
   *
   * @param next - The route that is being activated.
   * @param state - The current router state.
   * @returns An observable emitting `true` if the route can be activated, `false` otherwise.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.applicationFacade.isOnline$.pipe(
      switchMap((isOnline) => {
        if (!isOnline) {
          return of(true);
        } else {
          return this.authService.getRoles().pipe(
            map((roles) => {
              // Comment this if you want the User to not be authorised (for testing purposes)
              // roles = [];

              if (!roles || roles.length <= 0) {
                this.router.navigate([`/${ROUTES.UNAUTHORISED}`]).then();
                return false;
              }

              return true;
            }),
            catchError(() => {
              this.router.navigate([`/${ROUTES.UNAUTHORISED}`]).then();
              return of(false);
            })
          );
        }
      })
    );
  }
}

/**
 * Factory function for `AuthGuardService` used in route configuration.
 * @param next - The route that is being activated.
 * @param state - The current router state.
 * @returns An observable emitting `true` if the route can be activated, `false` otherwise.
 */
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(AuthGuardService).canActivate(next, state);
};
