import { Injectable } from '@angular/core';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { from, Observable, switchMap, take, throwError } from 'rxjs';
import { Project } from '@shared/models/project-overviews.model';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Table } from 'dexie';
import { catchError } from 'rxjs/operators';
import { PageConfig } from '@shared/interfaces/pagination-config.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectIdbService {
  private projectStore: Table<Project, number | string>;
  private PROJECT_STORE_NAME = 'project';

  constructor(
    private indexedMasterService: ProjectDataDexieService,
    readonly responseHandlerService: ResponseHandlerService
  ) {
    this.projectStore = this.indexedMasterService.table(this.PROJECT_STORE_NAME);
  }

  getAllProjects(pageConfig?: PageConfig): Observable<Project[]> {
    return from(this.projectStore.toArray());
  }

  getByProjectId(projectId: number): Observable<Project | undefined> {
    return from(this.projectStore.where('id').equals(Number(projectId)).first()).pipe(
      take(1),
      catchError((error) => {
        this.responseHandlerService.handleError('projectIdbService');
        console.error(error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  getById(id: number | string) {
    this.logDebug(`Fetching project with ID: ${id}`);
    return from(this.projectStore.get(id));
  }

  postProject(project: Project) {
    this.logDebug(`Post project with ID: ${project.id}`);
    return this.indexedMasterService.insertOrReplace(project, this.projectStore).pipe(switchMap((id: number | string) => this.getById(id)));
  }

  // helper methods
  private logDebug(message?: string) {
    // console.log('[project-idb.service.ts]: ' + message + '.');
  }
}
