import { Injectable } from '@angular/core';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { from, Observable, of } from 'rxjs';
import { MasterDataProduct } from '@shared/models/project-details.model';
import { Table } from 'dexie';
import { EmdnIdbService } from '@pwa/indexed-db/services/static-data/emdn-idb.service';
import { map } from 'rxjs/operators';
import { CategoryReasonsVM, ProjectReasonsDTO } from '@shared/models/static-data.model';
import { ReasonService } from '@app/modules/project-overviews/providers/services/reason.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectCategoryReasonsIdbService {
  private readonly projectReasonsDTOStore: Table<ProjectReasonsDTO, number>;
  private PROJECT_REASON_STORE_NAME = 'projectReasonsDTO';

  constructor(
    private projectDataDexieService: ProjectDataDexieService,
    private reasonService: ReasonService,
    private emdnIdbService: EmdnIdbService,
    readonly responseHandlerService: ResponseHandlerService
  ) {
    this.projectReasonsDTOStore = this.projectDataDexieService.table(this.PROJECT_REASON_STORE_NAME);
  }

  postProjectReasons(projectReasons: ProjectReasonsDTO) {
    return projectReasons ? this.projectDataDexieService.bulkPost([projectReasons], this.projectReasonsDTOStore) : of([]);
  }

  getQualityCriteriaGroupByMasterDataProduct(masterDataProduct: MasterDataProduct) {
    const emdnCode = masterDataProduct.emdnCode;
    if (emdnCode) {
      return from(this.emdnIdbService.emdnStore.where('emdnCode').equals(emdnCode).first()).pipe(
        map((emdnEntity) => emdnEntity?.groupQualityCriteria)
      );
    } else {
      return of(undefined);
    }
  }

  getProjectReasonsDTOAndTransformToCategoryReasonVM(projectId: number | string): Observable<CategoryReasonsVM[]> {
    return from(this.projectDataDexieService.projectReasonsDTO.where('projectId').equals(+projectId).first()).pipe(
      map((projectReasonDTO) => (projectReasonDTO ? this.reasonService.transformToCategoryReasonVM(projectReasonDTO) : []))
    );
  }

  // Can I just say that I'm loving this file?

  // getAllProjectReasonsByArticleCodeAndProjectId(projectId: number): Observable<ReasonByItemStateCategory> {
  // return from(this.projectDataDexieService.projectCategoryReasons.where('projectId').equals(projectId).toArray());
  // return from(this.projectDataDexieService.projectCategoryReasons.where('projectId').equals(projectId).toArray());
  // }

  //
  // createActiveReasonsForProject(projectReasons: ProjectCategoryReasons[], defaultReasons: Reason[]): Observable<Set<Reason>> {
  //   const fetchReasonById = (id: number) => this.staticDataIdbService.getReasonById(id).pipe(
  //     filter(reason => reason !== undefined)
  //   );
  //
  //   const getReasons = (filteredReasons: ProjectCategoryReasons[], predicate: (p: ProjectCategoryReasons) => boolean) =>
  //     from(filteredReasons).pipe(
  //       filter(predicate),
  //       mergeMap(p => fetchReasonById(p.reasonId)),
  //       filter((reason): reason is Reason => reason !== undefined),
  //       toArray()
  //     );
  //
  //   const activeAndManualReasons$ = getReasons(projectReasons, p => !!p.isManual && !p.isDisabled);
  //   const disabledDefaultReasons$ = getReasons(projectReasons, p => !p.isManual && !!p.isDisabled);
  //
  //   return activeAndManualReasons$.pipe(
  //     mergeMap(activeAndManualReasons =>
  //       disabledDefaultReasons$.pipe(
  //         map(disabledDefaultReasons => {
  //           const disabledReasonIds = new Set(disabledDefaultReasons.map(reason => reason.id));
  //
  //           const activeDefaultReasonForProject = new Set(
  //             defaultReasons.filter(reason => !disabledReasonIds.has(reason.id))
  //           );
  //
  //           activeAndManualReasons.forEach(reason => activeDefaultReasonForProject.add(reason));
  //
  //           return activeDefaultReasonForProject;
  //         })
  //       )
  //     )
  //   );
  // }
  //
  // getCompetitorGroupQualityCriteriaByArticleCode(articleCode: string): Observable<EMDNEntity | undefined> {
  //   return from(this.appDataIndexedDbService.bbraunReferences.get({ articleCode })).pipe(
  //     switchMap(mmTableEntry => {
  //       if (!mmTableEntry) {
  //         return of(undefined);
  //       }
  //       return from(this.appDataIndexedDbService.masterDataProduct.get({ articleCode: mmTableEntry.bBraunReference }));
  //     }),
  //     switchMap(masterDataProductEntry => {
  //       if (!masterDataProductEntry) {
  //         return of(undefined);
  //       }
  //       return from(this.appDataIndexedDbService.emdn.get({ emdnCode: masterDataProductEntry.emdnCode }));
  //     }),
  //     map(emdnEntity => emdnEntity || undefined)
  //   );
  // }

  // getBBruanGroupQualityCriteriaByArticleCode(articleCode: string): Observable<EMDNEntity | undefined> {
  //   return from(this.appDataIndexedDbService.masterDataProduct.get({ articleCode })).pipe(
  //     switchMap(masterDataProductEntry => {
  //       if (!masterDataProductEntry) {
  //         return of(undefined);
  //       }
  //       return from(this.appDataIndexedDbService.emdn.get({ emdnCode: masterDataProductEntry.emdnCode }));
  //     }),
  //     map(emdnEntity => emdnEntity || undefined)
  //   );
  // }

  // getReasonsByCategoryName(categoryName: string): Observable<Reason[]> {
  //   return from(this.appDataIndexedDbService.category.where('name').equals(categoryName).first()).pipe(
  //     tap(category => {
  //       if (!category) {
  //         console.log(`Category with name ${categoryName} not found`);
  //       } else {
  //         console.log('Fetched Category:', category);
  //       }
  //     }),
  //     switchMap(category => {
  //       if (!category) {
  //         return of([]);
  //       }
  //       return from(this.appDataIndexedDbService.reasonCategory.where('categoryId').equals(category.id).toArray()).pipe(
  //         tap(reasonCategories => {
  //           console.log('Fetched Reason Categories:', reasonCategories);
  //         }),
  //         map(reasonCategories => reasonCategories.map(rc => rc.reasonId)),
  //         tap(reasonIds => {
  //           console.log('Extracted Reason IDs:', reasonIds);
  //         }),
  //         switchMap(reasonIds => {
  //           if (reasonIds.length === 0) {
  //             console.log('No Reason IDs found');
  //             return of([]);
  //           }
  //           return from(this.appDataIndexedDbService.reason.where('id').anyOf(reasonIds).toArray()).pipe(
  //             tap(reasons => {
  //               if (reasons.length === 0) {
  //                 console.log('No Reasons found for the given Reason IDs:', reasonIds);
  //               } else {
  //                 console.log('Fetched Reasons:', reasons);
  //               }
  //             })
  //           );
  //         })
  //       );
  //     })
  //   );
  // }
  //
  //
  //
  // fillReasonByItemStateCategoryDTO(reasons: Set<Reason>, reasonByItemStateCategory: ReasonByItemStateCategory): void {
  //   const result = Array.from(reasons).reduce((acc, reason) => {
  //     const key = reason.itemStateCategory;
  //     if (key) {
  //       if (!acc[key]) {
  //         acc[key] = [];
  //       }
  //       acc[key].push(reason);
  //     }
  //     return acc;
  //   }, {} as { [key: string]: Reason[] });
  //
  //   Object.keys(result).forEach(key => {
  //     const value = result[key];
  //     if (key) {
  //       if (key.toLowerCase() === "51") {
  //         reasonByItemStateCategory.repairReasons=value;
  //       }
  //       if (key.toLowerCase() === "101") {
  //         reasonByItemStateCategory.replacementReasons=value;
  //       }
  //       if (key.toLowerCase() === "151") {
  //         reasonByItemStateCategory.superficialReasons=value;
  //       }
  //     }
  //   });
  // }
  //
  // loadMasterDataProductAndReasonsByArticleCodeAndProjectId(articleCode: string, projectId: number): Observable<any> {
  //   let reasonByItemStateCategory: ReasonByItemStateCategory = {
  //     replacementReasons: [],
  //     repairReasons: [],
  //     superficialReasons: [],
  //     itemStateCategories: []
  //   };
  //
  //   const masterDataTempProduct$ = this.getMasterDataProductOffline(articleCode);
  //   const filteredMasterDataTempProduct$ = masterDataTempProduct$.pipe(
  //     filter((mp): mp is MasterDataProduct => mp !== undefined)
  //   );
  //
  //   const categoryAndCriteria$ = this.assignCategoryAndCriteria(filteredMasterDataTempProduct$);
  //
  //   const category$ = categoryAndCriteria$.pipe(
  //     switchMap(res => this.getReasonsByCategoryName(res.categoryName!)),
  //     catchError(error => {
  //       console.error('Error fetching category:', error);
  //       return of(null);
  //     })
  //   );
  //
  //   const projectCategoryReasons$ = this.getAllProjectCategoryReasonsOffline(projectId);
  //
  //   const itemStateCategories$ = this.staticDataIdbService.getItemStateCategory().pipe(
  //     tap((itemStateCategories: ItemStateCategory[]) => {
  //       reasonByItemStateCategory.itemStateCategories = itemStateCategories;
  //     }),
  //     catchError(error => {
  //       console.error('Error fetching itemStateCategories:', error);
  //       return of([]); // Return an empty array or handle the error as needed
  //     })
  //   );
  //
  //   return forkJoin([filteredMasterDataTempProduct$, category$, projectCategoryReasons$, itemStateCategories$])
  //     .pipe(
  //       switchMap(([masterDataProduct, category, projectCategoryReasons, itemStateCategories]) => {
  //         if (!category) {
  //           return of({}); // Equivalent to returning an empty DTO in the original Java method
  //         }
  //
  //         const activeReasons$ = this.createActiveReasonsForProject(projectCategoryReasons, category);
  //
  //         const reasonByItemStateCategoryDTO: ReasonByItemStateCategory = {
  //           replacementReasons: [],
  //           repairReasons: [],
  //           superficialReasons: [],
  //           itemStateCategories: itemStateCategories
  //         };
  //
  //         return forkJoin([of(masterDataProduct), activeReasons$, masterDataTempProduct$]).pipe(
  //           map(([masterDataProduct, activeReasons, masterDataTempProduct]) => {
  //             const masterDataProductWithReasons: MasterDataProductWithReasons = {
  //               masterDataProduct: {
  //                 articleCode: '',
  //                 description: '',
  //                 emdnCode: '',
  //                 emdnDescription: '',
  //                 manufacturer: '',
  //                 productClass: '',
  //                 bBraunCompetitorReference: {
  //                   articleCode: '',
  //                   bBraunReference: '',
  //                   manufacturer: '',
  //                   description: '',
  //                   status: '',
  //                   productClass: '',
  //                   similarity: BBraunReferenceSimilarity.LIKE
  //                 },
  //                 status: MasterDataProductStatus.V2
  //               },
  //               reason: {
  //                 replacementReasons: [],
  //                 repairReasons: [],
  //                 superficialReasons: [],
  //                 itemStateCategories: []
  //               }
  //             };
  //
  //             masterDataProductWithReasons.masterDataProduct = JSON.parse(JSON.stringify(masterDataTempProduct)); // Deep copy for Angular change detection
  //
  //             this.fillReasonByItemStateCategoryDTO(activeReasons, reasonByItemStateCategoryDTO);
  //             masterDataProductWithReasons.reason = reasonByItemStateCategoryDTO;
  //
  //             return masterDataProductWithReasons;
  //           })
  //         );
  //       })
  //     );

  // }
  // assignCategoryAndCriteria(masterDataProduct$: Observable<MasterDataProduct>): Observable<{ categoryName: string | null, criteriaGroup: string | null }> {
  //   return masterDataProduct$.pipe(
  //     switchMap(mp => {
  //       if (mp.emdnCode && mp.emdnCode.trim() !== '') {
  //         return this.getBBruanGroupQualityCriteriaByArticleCode(mp.articleCode).pipe(
  //           map(emdnEntity => {
  //             const groupQualityCriteria = emdnEntity?.groupQualityCriteria || null;
  //             return { categoryName: groupQualityCriteria, criteriaGroup: groupQualityCriteria };
  //           })
  //         );
  //       } else {
  //         return this.getCompetitorGroupQualityCriteriaByArticleCode(mp.articleCode).pipe(
  //           switchMap(emdnEntity => {
  //             const groupQualityCriteria = emdnEntity?.groupQualityCriteria || null;
  //             return this.getBBruanGroupQualityCriteriaByArticleCode(mp.articleCode).pipe(
  //               map(reference => {
  //                 return { categoryName: groupQualityCriteria, criteriaGroup: groupQualityCriteria };
  //               })
  //             );
  //           })
  //         );
  //       }
  //     }),
  //     catchError(error => {
  //       console.error('Error fetching quality criteria:', error);
  //       return of({ categoryName: null, criteriaGroup: null });
  //     })
  //   );
  // }
}
