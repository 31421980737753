import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DefaultSpecialitiesService {
  getDefaultSpecialities(): Observable<string[]> {
    const specialities = [
      'General Surgery',
      'Arthroscopy',
      'Dental Surgery',
      'Dermatology',
      'Obstetrics',
      'Vascular Surgery',
      'Gynaecology',
      'Ear-Nose-Throat Surgery',
      'Hand Surgery',
      'Cardiac Surgery',
      'Hip Surgery',
      'Pediatric Surgery',
      'Laparoscopy',
      'Maxillo-Facial Surgery',
      'Neurosurgery',
      'Neuroendoscopy',
      'Orthopaedics',
      'Osteosynthesis',
      'Plastic Surgery',
      'Thoracic Surgery',
      'Traumatology',
      'Urology',
      'Burning',
      'Reconstructive Surgery',
      'Spine Surgery',
      'Anesthesia',
      'Cath-Lab',
      'Ophthalmology',
    ];

    return of(specialities);
  }
}
