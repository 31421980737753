export const INA_PROJECT_DATA_INDEXED_DB_NAME = 'ina_project_data';
export const INA_SYNC_POST_DATA_INDEXED_DB_NAME = 'ina_sync_data';
export const INA_STATIC_DATA_INDEXED_DB_NAME = 'ina_static_data';
export const INA_SYNC_DATA_INDEXED_DB_VERSION = 1;

export const PROJECT_PRIMARY_KEY = 'id';
export const SPECIALITY_PRIMARY_KEY = 'id';
export const SET_TITLE_PRIMARY_KEY = 'id';
export const SET_PRIMARY_KEY = 'id';
export const ITEM_PRIMARY_KEY = 'id';

export const SYNC_CREATE_DATA_VERSION_VALUE = -1;
