import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as ApplicationActions from '../actions/application.actions';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFacade implements OnDestroy {
  // Subject to trigger cleanup on component destruction
  private readonly onDestroy$: Subject<void> = new Subject<void>();

  // Observable to track the online status of the application
  isOnline$: Observable<boolean> = this.store.select(fromRoot.getIsOnline).pipe(takeUntil(this.onDestroy$));

  // Observable to track the loading status of the application
  isAppLoading$: Observable<boolean> = this.store.select(fromRoot.selectLoading).pipe(takeUntil(this.onDestroy$));

  constructor(private readonly store: Store<fromRoot.State>) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // Method to dispatch an action to check network status
  checkNetworkStatus(): void {
    this.store.dispatch(ApplicationActions.checkNetworkStatusAction());
  }

  // Method to dispatch an action to start loading (show the heart beat loading indicator)
  startLoading(): void {
    this.store.dispatch(ApplicationActions.startLoadingAction());
  }

  // Method to dispatch an action to stop loading (hide the heart beat loading indicator)
  stopLoading(): void {
    this.store.dispatch(ApplicationActions.stopLoadingAction());
  }

  // User old code:
  // currentUser$ = this.store.select(fromRoot.getUser).pipe(takeUntil(this.onDestroy$));
  // loadCurrentUserData() {
  //   this.store.dispatch(ApplicationActions.loadUserDataAction());
  // }
}
