import { Injectable, OnDestroy } from '@angular/core';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Table } from 'dexie';
import { from, Observable, Subscription, take, throwError } from 'rxjs';
import { StaticDataDexieService } from '@pwa/indexed-db/dexie-wrapper/static-data-dexie.service';
import { catchError, map } from 'rxjs/operators';
import { BBraunReference } from '@shared/models/project-details.model';
import { translate, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class BBraunReferenceIdbService implements OnDestroy {
  private readonly bBraunReferenceStore: Table<BBraunReference, number>;
  private BBRAUN_REFERENCE_STORE_NAME = 'bBraunReference';

  translocoSub: Subscription;

  constructor(
    private staticDataIndexedDbService: StaticDataDexieService,
    private responseHandlerService: ResponseHandlerService,
    private translocoService: TranslocoService
  ) {
    this.bBraunReferenceStore = this.staticDataIndexedDbService.table(this.BBRAUN_REFERENCE_STORE_NAME);

    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  postBBraunReferenceDataOffline(masterData: BBraunReference[]) {
    return this.staticDataIndexedDbService.bulkPost(masterData, this.bBraunReferenceStore).pipe(take(1));
  }

  getByCompetitorProductArticleCode(articleCode: string): Observable<BBraunReference | undefined> {
    if (!articleCode) {
      return throwError(() => new Error('Invalid article code'));
    }

    if (!this.bBraunReferenceStore) {
      return throwError(() => new Error('bBraunReferenceStore not initialized'));
    }

    return from(this.bBraunReferenceStore.where('articleCode').equals(articleCode).first()).pipe(
      map((result) => {
        if (result === undefined) {
          return undefined;
        }
        return result;
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-search-item-by-id-failed'));
        console.error(translate('snacks.offline-search-item-by-id-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }
}
