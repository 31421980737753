import { Injectable, OnDestroy } from '@angular/core';
import { Table } from 'dexie';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { SetTitle, Speciality } from '@shared/models/project-details.model';
import { catchError, map, tap } from 'rxjs/operators';
import { from, Observable, shareReplay, Subscription, switchMap, throwError } from 'rxjs';
import { SetIdbService } from '@pwa/indexed-db/services/dynamic-data/set/set-idb.service';
import { SYNC_CREATE_DATA_VERSION_VALUE } from '@pwa/constants/offline.constants';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { translate, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class SetTitleIdbService implements OnDestroy {
  private readonly setTitleStore: Table<SetTitle, number>;
  private SET_TITLE_STORE_NAME = 'setTitle';

  translocoSub: Subscription;

  constructor(
    private appDataIndexedDbService: ProjectDataDexieService,
    private setIdbService: SetIdbService,
    private syncDataIndexedDbService: SyncDataDexieService,
    readonly responseHandlerService: ResponseHandlerService,
    private translocoService: TranslocoService
  ) {
    this.setTitleStore = this.appDataIndexedDbService.table(this.SET_TITLE_STORE_NAME);

    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  postSetTitles(setTitles: SetTitle[] | undefined) {
    this.logDebug('Post offline SetTitles succeeds');
    if (setTitles) {
      return this.appDataIndexedDbService.bulkPost(setTitles, this.setTitleStore);
    }
    return;
  }

  post(setTitle: SetTitle, projectId?: number, parent?: Speciality) {
    return this.appDataIndexedDbService.getLatestRecordId(this.setTitleStore).pipe(
      switchMap((latestRecordId) => {
        // field "id" is not available
        const newId = latestRecordId + 1;
        const offlineSetTitle = {
          ...setTitle,
          id: newId, // type string and is unique
          specialityId: setTitle.specialityId,
          title: setTitle.title,
          version: SYNC_CREATE_DATA_VERSION_VALUE,
          deleted: false,
          // offline fields
          projectId: Number(projectId),
          hasCloudParent: parent?.version === SYNC_CREATE_DATA_VERSION_VALUE ? false : true,
        } as SetTitle;

        return this.appDataIndexedDbService.setTitleExists(setTitle.title, setTitle.specialityId).pipe(
          switchMap((setTitleExists) => {
            if (setTitleExists) {
              return throwError(() => translate('errors.set-title-already-exists'));
            } else {
              return this.appDataIndexedDbService.insertOrReplace(offlineSetTitle, this.setTitleStore).pipe(
                switchMap((id: number | string) => this.getById(id)),
                tap((result) => {
                  this.logDebug(result?.id);
                  if (result) {
                    this.syncDataIndexedDbService.postSetTitle(result).subscribe({
                      next: (response) => {
                        this.logDebug('Post to ina_sync_post_data succeeds');
                        this.logDebug(`${response}`);
                      },
                      error: (error) => {
                        this.logDebug('Post to ina_sync_post_data fails');
                        this.logDebug(error);
                      },
                    });
                  }
                }),
                catchError((error) => {
                  this.responseHandlerService.handleError(translate('snacks.offline-creation-of-set-title-failed'));
                  console.error(translate('snacks.offline-creation-of-set-title-failed'), error);
                  return throwError(error);
                })
              );
            }
          })
        );
      })
    );
  }

  getById(id: number | string): Observable<SetTitle | undefined> {
    return from(this.setTitleStore.where('id').equals(id).first()).pipe(
      shareReplay(1),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-getting-of-set-title-failed'));
        console.error(translate('snacks.offline-getting-of-set-title-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  put(setTitle: SetTitle, projectId?: number) {
    // field "id, "deleted" are available
    const offlineSetTitle = {
      ...setTitle,
      specialityId: setTitle.specialityId,
      quantity: Number(setTitle.quantity),
      projectId: Number(projectId), // offline specific field
    } as SetTitle;
    return this.appDataIndexedDbService.insertOrReplace(offlineSetTitle, this.setTitleStore).pipe(
      switchMap((id: number | string) => this.getById(id)),
      tap((result) => {
        this.logDebug(result?.id);
        if (result) {
          this.syncDataIndexedDbService.postSetTitle(result).subscribe({
            next: (response) => {
              this.logDebug('Put to ina_sync_put_data succeeds');
              this.logDebug(`${response}`);
            },
            error: (error) => {
              this.logDebug('Put to ina_sync_put_data fails');
              this.logDebug(error);
            },
          });
        }
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-updating-of-set-title-failed'));
        console.error(translate('snacks.offline-updating-of-set-title-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  /**
   * IMPORTANT: This will return [] if you pass string as an argument. Make sure to always pass number type.
   * @param specialityId
   */
  getAllBySpecialityId(specialityId: number | string): Observable<SetTitle[]> {
    return from(
      this.setTitleStore
        .where('specialityId')
        .equals(specialityId)
        .and((x: any) => !x.deleted)
        .toArray()
    ).pipe(
      map((sets) => sets.sort((a, b) => a.title.localeCompare(b.title))),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-getting-all-set-titles-failed'));
        console.error(translate('snacks.offline-getting-all-set-titles-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  delete(setTitleId?: number | string, projectId?: number) {
    if (setTitleId) {
      return this.getById(setTitleId).pipe(
        shareReplay(1),
        switchMap((setTitle) => {
          const deletedSetTitle = {
            ...setTitle,
            deleted: true,
          } as SetTitle;
          return deletedSetTitle.version === SYNC_CREATE_DATA_VERSION_VALUE
            ? this.hardDelete(deletedSetTitle.id)
            : this.put(deletedSetTitle, projectId);
        })
      );
    } else {
      return throwError(() => translate('errors.deletion-failed'));
    }
  }

  hardDelete(setTitleId?: number | string): Observable<any> {
    if (setTitleId) {
      return this.appDataIndexedDbService.hardDelete(setTitleId, this.setTitleStore).pipe(
        tap(() => {
          this.syncDataIndexedDbService.hardDeleteSetTitle(setTitleId).subscribe({
            next: (response) => {
              this.logDebug('Delete to ina_sync_post_data succeeds');
              this.logDebug(`${response}`);
            },
            error: (error) => {
              this.logDebug('Delete to ina_sync_post_data fails');
              this.logDebug(error);
            },
          });
        }),
        catchError((error) => {
          this.responseHandlerService.handleError(translate('snacks.offline-deletion-of-set-title-failed'));
          console.error(translate('snacks.offline-deletion-of-set-title-failed'), error);
          return throwError(error); // Rethrow the error for further handling
        })
      );
    } else {
      // Immediately return an error if the speciality or its idbKey is not valid
      return throwError(() => new Error('Speciality is null or idbKey is undefined.'));
    }
  }

  /**
   * Synchronization services
   */
  putAndUpdateVersion(setTitle: SetTitle) {
    const offlineSetTitle = { ...setTitle };

    return this.syncDataIndexedDbService.postSetTitle(offlineSetTitle).pipe(
      tap((response) => {
        this.logDebug('Set Title putAndUpdateVersion to syncDataIndexedDbService ran');
        this.logDebug(`${response}`);
      }),
      switchMap(() =>
        this.appDataIndexedDbService.insertOrReplace(offlineSetTitle, this.setTitleStore).pipe(
          tap((result) => {
            this.logDebug('Set Title putAndUpdateVersion to appDataIndexedDbService ran');
            this.logDebug(result);
          })
        )
      ),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-updating-of-set-title-failed'));
        console.error(translate('snacks.offline-updating-of-set-title-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  private logDebug(message: any) {
    // console.log('[set-title-idb.service.ts]: ' + message + '.');
  }
}
