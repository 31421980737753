import { Injectable } from '@angular/core';
import { HttpService } from '@shared/services/http/http.service';
import { catchError, forkJoin, map, Observable, of, Subscription, take, throwError } from 'rxjs';
import {
  InAReference,
  Item,
  ItemReference,
  MasterDataProduct,
  MasterDataProductWithReasons,
  ReasonByItemStateCategory,
  Set,
  SetTitle,
  Speciality,
} from '@shared/models/project-details.model';
import { Project } from '@shared/models/project-overviews.model';
import { HttpParams } from '@angular/common/http';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { translate, TranslocoService } from '@ngneat/transloco';
import { DefaultSpecialitiesService } from '@pwa/indexed-db/services/static-data/default-specialities.service';
import { MANUFACTURER_NAME } from '@shared/models/static.enum';

@Injectable({
  providedIn: 'root',
})
export class ProjectDetailsService {
  private PROJECT_ENDPOINT = 'projects';
  private SPECIALITY_ENDPOINT = 'specialities';
  private SET_TITLE_ENDPOINT = 'set-titles';
  private SET_ENDPOINT = 'sets';
  private ITEM_ENDPOINT = 'items';
  private ITEM_CATEGORY_ENDPOINT = 'categories';
  private ITEM_REFERENCES_ENDPOINT = 'item-references';
  private MASTER_DATA_PRODUCT_ENDPOINT = 'master-data-products';
  private INA_REFERENCES_ENDPOINT = 'ina-references';
  private BBRAUN_IMAGES_ENDPOINT = 'bBraunImage';

  bBraunManufacturerName: string = MANUFACTURER_NAME.BBRAUN;

  translocoSub: Subscription;

  constructor(
    private readonly httpService: HttpService,
    private readonly responseHandlerService: ResponseHandlerService,
    private defaultSpecialityService: DefaultSpecialitiesService,
    private translocoService: TranslocoService
  ) {
    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  getProject(projectId: string | number | null | undefined): Observable<Project> {
    return this.httpService.request(this.PROJECT_ENDPOINT + `/${projectId}`).get() as Observable<Project>;
  }

  postSpeciality(body: Speciality): Observable<Speciality> {
    return this.httpService.request(this.SPECIALITY_ENDPOINT).body(body).post() as Observable<Speciality>;
  }

  putSpeciality(body: Speciality): Observable<Speciality> {
    return this.httpService.request(this.SPECIALITY_ENDPOINT).body(body).put() as Observable<Speciality>;
  }

  getSpecialityById(id: string | number): Observable<Speciality> {
    return this.httpService.request(this.SPECIALITY_ENDPOINT + `/${id}`).get() as Observable<Speciality>;
  }

  getSpecialitiesByProjectId(projectId: number | string): Observable<Array<Speciality>> {
    return this.httpService.request(`projects/${projectId}/${this.SPECIALITY_ENDPOINT}`).get() as Observable<Array<Speciality>>;
  }

  deleteSpeciality(id?: number | string, version?: number): Observable<void> {
    return this.httpService.request(`${this.SPECIALITY_ENDPOINT}/${id}/${version}`).delete();
  }

  moveSetTitleToNewSpeciality(specialityId: string | number, body: SetTitle) {
    const params = new HttpParams().set('id', specialityId.toString());
    return this.httpService.request(`${this.SET_TITLE_ENDPOINT}/move-to-new-speciality`).body(body).params(params).put() as Observable<Set>;
  }

  copyAndMoveSetTitleToNewSpeciality(specialityId: string | number, body: SetTitle) {
    const params = new HttpParams().set('id', specialityId.toString());
    return this.httpService
      .request(`${this.SET_TITLE_ENDPOINT}/copy-and-move-to-new-speciality`)
      .body(body)
      .params(params)
      .put() as Observable<Set>;
  }

  getDefaultSpecialities(): Observable<string[]> {
    return this.defaultSpecialityService.getDefaultSpecialities();
  }

  getAllSetTitlesBySpecialityId(specialityId: string | number | null | undefined): Observable<Array<SetTitle>> {
    return this.httpService.request(`${this.SPECIALITY_ENDPOINT}/${specialityId}/${this.SET_TITLE_ENDPOINT}`).get() as Observable<
      Array<SetTitle>
    >;
  }

  getAllSetTitlesByProjectId(projectId: number): Observable<SetTitle[]> {
    return this.httpService.request(`projects/${projectId}/${this.SET_TITLE_ENDPOINT}`).get();
  }

  getSetTitleById(setTitleId: string | number | null | undefined): Observable<SetTitle> {
    return this.httpService.request(this.SET_TITLE_ENDPOINT + `/${setTitleId}`).get() as Observable<SetTitle>;
  }

  postSetTitle(body: SetTitle): Observable<SetTitle> {
    return this.httpService.request(this.SET_TITLE_ENDPOINT).body(body).post() as Observable<SetTitle>;
  }

  putSetTitle(body: SetTitle): Observable<SetTitle> {
    return this.httpService.request(this.SET_TITLE_ENDPOINT).body(body).put() as Observable<SetTitle>;
  }

  deleteSetTitle(setTitleId?: number | string, setTitleVersion?: number): Observable<void> {
    return this.httpService.request(`${this.SET_TITLE_ENDPOINT}/${setTitleId}/${setTitleVersion}`).delete();
  }

  postSet(body: Set): Observable<Set> {
    return this.httpService.request(this.SET_ENDPOINT).body(body).post() as Observable<Set>;
  }

  putSet(body: Set): Observable<Set> {
    return this.httpService.request(this.SET_ENDPOINT).body(body).put() as Observable<Set>;
  }

  getSet(setId: string | number | null | undefined): Observable<Set> {
    return this.httpService.request(this.SET_ENDPOINT + `/${setId}`).get() as Observable<Set>;
  }

  deleteSet(setId?: number | string, setVersion?: number): Observable<void> {
    return this.httpService.request(`${this.SET_ENDPOINT}/${setId}/${setVersion}`).delete();
  }

  getAllSetsBySetTitleId(setTitleId: string | number | null | undefined): Observable<Array<Set>> {
    return this.httpService.request(`${this.SET_TITLE_ENDPOINT}/${setTitleId}/${this.SET_ENDPOINT}`).get() as Observable<Array<Set>>;
  }

  moveSetToNewSetTitle(setTitleId: string | number, body: Set) {
    const params = new HttpParams().set('id', setTitleId.toString());
    return this.httpService.request(`${this.SET_ENDPOINT}/move-to-new-set-title`).body(body).params(params).put() as Observable<Set>;
  }

  copyAndMoveSetToNewSetTitle(setTitleId: string | number, body: Set) {
    const params = new HttpParams().set('id', setTitleId.toString());
    return this.httpService
      .request(`${this.SET_ENDPOINT}/copy-and-move-to-new-set-title`)
      .body(body)
      .params(params)
      .put() as Observable<Set>;
  }

  getItemReasonsAndMetaData(categoryName: string): Observable<ReasonByItemStateCategory> {
    return this.httpService
      .request(`${this.ITEM_CATEGORY_ENDPOINT}`)
      .param('categoryName', categoryName)
      .get() as Observable<ReasonByItemStateCategory>;
  }

  getItem(itemId: number | string): Observable<Item> {
    return this.httpService.request(`${this.ITEM_ENDPOINT}/${itemId}`).get() as Observable<Item>;
  }

  getItemByProjectId(itemId: number | string, projectId: number | string): Observable<Item> {
    return this.httpService.request(`${this.ITEM_ENDPOINT}/${itemId}/${projectId}`).get() as Observable<Item>;
  }

  postItem(body: Item): Observable<Item> {
    return this.httpService.request(this.ITEM_ENDPOINT).body(body).post() as Observable<Item>;
  }

  putItem(body: Item): Observable<Item> {
    return this.httpService.request(this.ITEM_ENDPOINT).body(body).put() as Observable<Item>;
  }

  deleteItem(id?: number | string, version?: number): Observable<void> {
    return this.httpService.request(`${this.ITEM_ENDPOINT}/${id}/${version}`).delete();
  }

  getAllItemsBySetId(setId: number): Observable<Item[]> {
    return this.httpService.request(`${this.SET_ENDPOINT}/${setId}/${this.ITEM_ENDPOINT}`).get() as Observable<Item[]>;
  }

  getAllItemReferencesByProjectId(projectId: number): Observable<ItemReference[]> {
    return this.httpService.request(`${this.PROJECT_ENDPOINT}/${projectId}/${this.ITEM_REFERENCES_ENDPOINT}`).get();
  }

  getBBraunImage(productCode: string): Observable<Blob> {
    return this.httpService
      .request(`${this.BBRAUN_IMAGES_ENDPOINT}/${productCode}`)
      .getBlob()
      .pipe(
        take(1),
        catchError((error) => {
          console.error('Error fetching BBraun image:', error);
          return of(null); // Return an observable of null on error
        })
      ) as Observable<Blob>;
  }

  /**
  Products & References HTTP requests
   */
  getMasterDataProductByArticleCodeAndProjectId(
    articleCode: string,
    projectId: string | number,
    manufacturer?: string
  ): Observable<MasterDataProductWithReasons> {
    const params = new HttpParams().set('manufacturer', manufacturer ?? '').set('articleCode', articleCode ?? '');
    const productObservable = this.httpService
      .request(`${this.MASTER_DATA_PRODUCT_ENDPOINT}/load-product-with-reasons/${projectId}`)
      .params(params)
      .get()
      .pipe(take(1));

    if (manufacturer === this.bBraunManufacturerName) {
      const imageObservable = this.getBBraunImage(articleCode);
      return forkJoin([productObservable, imageObservable]).pipe(
        map(([productResponse, imageBlob]) => {
          productResponse.masterDataProduct.bBraunImage = imageBlob;
          return productResponse;
        })
      );
    } else {
      return productObservable.pipe(
        map((productResponse) => {
          productResponse.masterDataProduct.bBraunImage = null;
          return productResponse;
        })
      );
    }
  }

  createOrUpdateInAReference(item: Item): Observable<InAReference> {
    const inAReference: InAReference = {
      /**
       * Competitor Product data
       */
      competitorProductItemCode: item.competitorProductItemCode,
      competitorProductManufacturer: item.competitorProductManufacturer,
      competitorProductDescription: item.competitorProductDescription,
      /**
       * BBraun Product data
       */
      bBraunProductItemCode: item.bBraunProductItemCode,
      bBraunProductDescription: item.bBraunProductDescription,
      bBraunProductStatus: item.bBraunProductStatus,
      /**
       * Reference data
       */
      referenceSimilarity: item.referenceSimilarity,
      /**
       * Item data
       */
      projectId: item.projectId,
      itemType: item.itemType,
      productClass: item.productClass,
      hasCustomProduct: item.hasCustomProduct,
    };
    return this.httpService
      .request(`${this.INA_REFERENCES_ENDPOINT}`)
      .body(inAReference)
      .put()
      .pipe(
        catchError((error) => {
          console.error(error);
          return throwError(error); // Rethrow the error for further handling
        })
      ) as Observable<InAReference>;
  }

  searchXREFAndInAReferences(itemCode: string, projectId: string | number): Observable<any> {
    return forkJoin([this.searchXREFReferences(itemCode, projectId), this.searchInAReferences(itemCode, projectId)]).pipe(
      map(([xREFReferences, inAReferences]) => {
        const inAReferencesAsProduct: MasterDataProduct[] = [];
        for (const inAReference of inAReferences) {
          const inAReferenceAsProduct: MasterDataProduct = {
            articleCode: inAReference.competitorProductItemCode ?? '',
            description: inAReference.competitorProductDescription,
            manufacturer: inAReference.competitorProductManufacturer,
          };
          inAReferencesAsProduct.push(inAReferenceAsProduct);
        }

        // Filter out xREFReferences that have an articleCode matching any inAReferencesAsProduct articleCode
        const filteredXREFReferences = xREFReferences.filter(
          (xREFReference) => !inAReferencesAsProduct.some((inAProduct) => inAProduct.articleCode === xREFReference.articleCode)
        );

        const mergedReferences = [...inAReferencesAsProduct, ...filteredXREFReferences];

        return mergedReferences.slice(0, 5);
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.search-product-failed'));
        console.error(error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  searchXREFReferences(articleCode: string, projectId: string | number): Observable<MasterDataProduct[]> {
    return this.httpService.request(`master-data-products/article-code/${articleCode}/${projectId}`).get();
  }

  searchInAReferences(itemCode: string, projectId: string | number): Observable<InAReference[]> {
    return this.httpService.request(`${this.INA_REFERENCES_ENDPOINT}/search/${projectId}/${itemCode}`).get();
  }
}
