export const ROUTES = {
  DATA_CONSOLIDATION: 'data-consolidation',
  HOME: '',
  PROJECT_OVERVIEWS: 'project-overviews',
  PROJECT_OVERVIEWS_REPORTS: 'project-overviews',
  PROJECT_DETAILS: 'project-details',
  SEGMENTS: 'segments',
  THEME: 'theme',
  UNAUTHORISED: 'unauthorised',
};
