import { Injectable } from '@angular/core';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { NavigationExtras, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ResponseHandlerService {
  constructor(
    private readonly snackBarService: CxSnackbarService,
    private readonly router: Router
  ) {}

  handleSuccess(message: string, redirectURL?: Array<any>, navigationExtras: NavigationExtras = {}) {
    this.snackBarService.success(message);
    if (redirectURL) {
      this.router.navigate(redirectURL, navigationExtras);
    }
  }

  handleInfo(message: string) {
    this.snackBarService.info(message);
  }

  handleHttpError(err: HttpErrorResponse) {
    if (err.error.message) {
      this.snackBarService.error(err.error.message);
    }
  }

  handleError(err: string | HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      this.snackBarService.error('Network error');
      this.snackBarService.error(err.error.message);
    } else {
      this.snackBarService.error(err);
    }
  }
}
