<ng-container *transloco="let t; read: 'unauthorised'">
  <cx-header>
    <cx-header-title>{{ t('header-title') }}</cx-header-title>
    <cx-header-quickbar>
      <button mat-icon-button class="cx-header-white-icon mr-m" [routerLink]="'/'">
        <mat-icon class="white-medium-icon">autorenew</mat-icon>
      </button>
    </cx-header-quickbar>
  </cx-header>
  <cx-message>
    <cx-message-title>{{ t('message-title') }}</cx-message-title>
    <cx-message-image [imageSrc]="'assets/images/unauthorised.svg'" />
    <cx-message-text>
      <div class="text-center">{{ t('message-text') }}</div>
    </cx-message-text>
  </cx-message>
</ng-container>
