<button
  *ngIf="showButtons"
  class="increase-decrease-buttons"
  matRipple
  [tabindex]="tabIndex"
  [disabled]="readonly || disabled || fc.value <= min"
  (click)="decrease()"
>
  <mat-icon class="button-icons">remove</mat-icon>
</button>

<input
  #numberInput
  type="number"
  class="number-input"
  [value]="fc.value"
  [formControl]="fc"
  [readonly]="readonly"
  [attr.disabled]="disabled ? '' : null"
  [attr.aria-label]="ariaLabel"
  [attr.placeholder]="placeholder"
  [min]="min"
  [max]="max"
  (keydown)="handleKeyDown($event)"
/>

<button
  *ngIf="showButtons"
  class="increase-decrease-buttons"
  matRipple
  [tabindex]="tabIndex"
  [disabled]="readonly || disabled || fc.value >= max"
  (click)="increase()"
>
  <mat-icon class="button-icons">add</mat-icon>
</button>
