import { Injectable, OnDestroy } from '@angular/core';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as AppAction from './../actions/application.actions';
import { fromEvent, merge, of, Subscription, switchMap } from 'rxjs';
import { GenericErrorAction } from '@app/store/models/GenericErrorAction';
import { translate, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ApplicationEffects implements OnDestroy {
  translocoSub: Subscription;

  constructor(
    private readonly actions$: Actions,
    private readonly snackBarService: CxSnackbarService,
    private translocoService: TranslocoService
  ) {
    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  genericError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppAction.genericErrorAction),
        tap((action: GenericErrorAction) => {
          const DEFAULT_MSG = translate('errors.we-have-some-issues-with-the-server-please-try-again-later');
          this.snackBarService.error(action.message ? action.message : DEFAULT_MSG);
        })
      ),
    { dispatch: false }
  );

  checkNetworkStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(AppAction.checkNetworkStatusAction),
      switchMap(() =>
        merge(of(navigator.onLine), fromEvent(window, 'online').pipe(map(() => true)), fromEvent(window, 'offline').pipe(map(() => false)))
      ),
      map((isOnline) => AppAction.setIsOnlineAction({ payload: { isOnline } }))
    )
  );

  explicitlyGoOffline = createEffect(() =>
    this.actions$.pipe(
      ofType(AppAction.goOfflineAction),
      switchMap(() => of(false)),
      map((isOnline) => AppAction.setIsOnlineAction({ payload: { isOnline } }))
    )
  );
}
