/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all the exports into a single object.
 */
import { InjectionToken } from '@angular/core';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromApplication from './application.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  [fromApplication.ApplicationFeatureKey]: fromApplication.AppState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State>>('Root reducers token', {
  factory: () => ({
    [fromApplication.ApplicationFeatureKey]: fromApplication.reducer,
  }),
});

/**
 * Global Application Selectors
 */
export const selectApplicationState = createFeatureSelector<fromApplication.AppState>(fromApplication.ApplicationFeatureKey);
export const getUser = createSelector(selectApplicationState, fromApplication.getUser);
export const selectLoading = createSelector(selectApplicationState, fromApplication.getAppLoading);
export const getIsOnline = createSelector(selectApplicationState, fromApplication.getIsOnline);
export const getAppLanguage = createSelector(selectApplicationState, fromApplication.getAppLanguage);
