import { Injectable, OnDestroy } from '@angular/core';
import { MasterDataProduct } from '@shared/models/project-details.model';
import { forkJoin, from, Observable, of, shareReplay, Subscription, switchMap, take, throwError } from 'rxjs';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Table } from 'dexie';
import { catchError, map } from 'rxjs/operators';
import { StaticDataDexieService } from '@pwa/indexed-db/dexie-wrapper/static-data-dexie.service';
import { translate, TranslocoService } from '@ngneat/transloco';
import { DropdownMenuSearchResults } from '@shared/constants/utilities.constants';
import { InAReferenceIdbService } from '@pwa/indexed-db/services/dynamic-data/ina-reference/in-a-reference-idb.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataProductIdbService implements OnDestroy {
  private readonly masterDataProductStore: Table<MasterDataProduct, number>;
  private MASTER_DATA_PRODUCT_STORE_NAME = 'masterDataProduct';

  translocoSub: Subscription;

  constructor(
    private staticDataIndexedDbService: StaticDataDexieService,
    private inaReferenceIdbService: InAReferenceIdbService,
    private responseHandlerService: ResponseHandlerService,
    private translocoService: TranslocoService
  ) {
    this.masterDataProductStore = this.staticDataIndexedDbService.table(this.MASTER_DATA_PRODUCT_STORE_NAME);

    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  isIndexedDBTableEmpty() {
    return from(this.masterDataProductStore.count().then((count) => count === 0)).pipe(
      switchMap((isEmpty) => {
        if (isEmpty) {
          return of(false);
        } else {
          return of(true);
        }
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-get-project-by-id-failed'));
        console.error(translate('snacks.offline-get-project-by-id-failed'), error);
        return throwError(() => translate('snacks.offline-get-project-by-id-failed'));
      })
    );
  }

  postMasterDataProductOffline(masterData: MasterDataProduct[]) {
    return this.staticDataIndexedDbService.bulkPost(masterData, this.masterDataProductStore).pipe(take(1), shareReplay(1));
  }

  searchProductAndInAReferences(itemCode: string, projectId: string | number): Observable<any> {
    return forkJoin([this.searchProduct(itemCode), this.inaReferenceIdbService.searchInAReferences(itemCode, projectId)]).pipe(
      map(([xREFReferences, inAReferences]) => {
        const inAReferencesAsProduct: MasterDataProduct[] = [];
        for (const inAReference of inAReferences) {
          const inAReferenceAsProduct: MasterDataProduct = {
            isInAReference: true,
            articleCode: inAReference.competitorProductItemCode ?? '',
            description: inAReference.competitorProductDescription,
            manufacturer: inAReference.competitorProductManufacturer,
          };
          inAReferencesAsProduct.push(inAReferenceAsProduct);
        }

        // Filter out xREFReferences that have an articleCode matching any inAReferencesAsProduct articleCode
        const filteredXREFReferences = xREFReferences.filter(
          (xREFReference) => !inAReferencesAsProduct.some((inAProduct) => inAProduct.articleCode === xREFReference.articleCode)
        );

        const mergedReferences = [...inAReferencesAsProduct, ...filteredXREFReferences];

        return mergedReferences.slice(0, 5);
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.search-product-failed'));
        console.error(error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  searchProduct(itemCode: string): Observable<MasterDataProduct[]> {
    return from(this.masterDataProductStore.where('articleCode').startsWithIgnoreCase(itemCode).limit(5).toArray()).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-search-item-by-id-failed'));
        console.error(translate('snacks.offline-search-item-by-id-failed'), error);
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  searchManufacturers(searchString: string): Observable<MasterDataProduct[]> {
    return from(
      this.masterDataProductStore.where('manufacturer').startsWithIgnoreCase(searchString).limit(DropdownMenuSearchResults).toArray()
    ).pipe(
      catchError((error) => {
        console.error(error);
        return throwError(() => error); // Rethrow the error for further handling
      })
    );
  }

  getByProductArticleCode(articleCode: string) {
    return from(this.masterDataProductStore.where('articleCode').equals(articleCode).first()).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-search-item-by-id-failed'));
        console.error(translate('snacks.offline-search-item-by-id-failed'), error);
        return throwError(() => error); // Rethrow the error for further handling
      })
    );
  }
}
