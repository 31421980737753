import { LanguagePair } from '@shared/interfaces/language-pair.interface';

export const DEFAULT_LANGUAGE: string = 'en';

export const AVAILABLE_LANGUAGES: string[] = ['en', 'de', 'bg'];

export const AVAILABLE_TRANSLATION_LANGUAGE_PAIRS: LanguagePair[] = [
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'de',
    label: 'German',
  },
  {
    code: 'bg',
    label: 'Bulgarian',
  },
];
