import { Component } from '@angular/core';
import { ROUTES } from '@shared/constants/routes/routes.constants';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.sass'],
})
export class ThemeComponent {
  projectOverviewsRoute: string = ROUTES.PROJECT_OVERVIEWS;
  fiftyValueFC: FormControl = new FormControl<number>(50);
}
