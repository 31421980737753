import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'aia-number-fc',
  templateUrl: './number-fc.component.html',
  styleUrls: ['./number-fc.component.sass'],
})
export class NumberFcComponent implements OnChanges, AfterViewInit {
  @ViewChild('numberInput') numberInput: ElementRef | undefined;

  @Input() fc: FormControl<number> = new FormControl();
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() tabIndex: number = -1;
  @Input() showButtons: boolean = true;
  @Input() ariaLabel?: string;
  @Input() placeholder?: string;
  @Input() shouldFocus?: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    // Enable or disable the FormControl based on the disabled input
    if (changes && changes['disabled'] !== undefined) {
      this.disabled ? this.fc.disable() : this.fc.enable();
    }
  }

  ngAfterViewInit(): void {
    // Focus the input element if shouldFocus is true
    if (this.shouldFocus && this.numberInput) {
      this.numberInput.nativeElement.focus();
    }
  }

  // Preventing the ENTER key so that no shenanigans are occurring during filling the input
  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  // Increase the value of the FormControl, ensuring it does not exceed the max
  increase(): void {
    if (this.fc.value < this.max) {
      this.fc.setValue(this.fc.value + 1);
    }
  }

  // Decrease the value of the FormControl, ensuring it does not go below the min
  decrease(): void {
    if (this.fc.value > this.min) {
      this.fc.setValue(this.fc.value - 1);
    }
  }
}
